<script setup>
import {ref} from "vue";
import trackersReportsService from "@/services/trackers-reports.service";
import ArrayCell from "@/components/trackers/comparisonReport/ArrayCell.vue";

const showModal = ref(false);
const startups = ref({});
const features = ref({});

async function getReportData(logId) {
    const {data} = await trackersReportsService.getComparisonReportData(logId);
    [startups.value, features.value] = data;

    if (Object.keys(startups.value).length) {
        showModal.value = true;
    }
}

function open(logId) {
    getReportData(logId)
}

defineExpose({
    open
});
</script>

<template>
    <b-modal v-model="showModal" title="Feature Comparison" modal-class="modal-w-xxxxl" hide-footer>
        <div class="table-comparison">
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th v-for="(startupName, startupId) in startups" :key="startupId">{{startupName}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(featureData, featureName) in features" :key="featureName">
                        <td>{{featureName}}</td>
                        <td v-for="(_, startupId) in startups" :key="startupId">
                            <ArrayCell
                                v-if="Array.isArray(featureData[startupId])"
                                :items="featureData[startupId]"
                            />

                            <template v-else-if="typeof featureData[startupId] === 'number'">
                                {{featureData[startupId].toLocaleString()}}
                            </template>

                            <template v-else>
                                {{featureData[startupId]}}
                            </template>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </b-modal>
</template>

<style scoped>
</style>
